<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          :to="{ name: 'module-arnprior-pool-arnprior-pool-parties' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Party Bookings</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ pluralString(booking.order.customer.full_name) }} Party Booking
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.bookingDialog.open()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Party Booking</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">General Details</v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <td>{{ booking.formatted_dates.start_date }}</td>
                </tr>
                <tr>
                  <th>Start Time</th>
                  <td>{{ booking.formatted_dates.start_time }}</td>
                </tr>
                <tr>
                  <th>End Time</th>
                  <td>{{ booking.formatted_dates.end_time }}</td>
                </tr>
                <tr>
                  <th>Comments</th>
                  <td>It's our daughter's 8th birthday</td>
                </tr>
                <tr>
                  <th>Date Booking Created</th>
                  <td>{{ booking.formatted_dates.created_at }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">Attendees</v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Total Swimmers</th>
                  <td>{{ booking.total_swimmers }}</td>
                </tr>
                <tr>
                  <th>Total Poolside</th>
                  <td>{{ booking.total_poolside }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined>
            <v-card-title class="grey lighten-4">
              Order Details
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      :to="{
                        name: 'module-arnprior-customers-individual',
                        params: { customerId: booking.order.customer_id },
                      }"
                      >{{ booking.order.customer.full_name }}</router-link
                    >
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    <v-chip label small color="success">Paid</v-chip>
                  </td>
                </tr>
                <tr>
                  <th>Sub Total</th>
                  <td>£{{ booking.order.sub_total }}</td>
                </tr>
                <tr>
                  <th>VAT Total</th>
                  <td>£{{ booking.order.vat_total }}</td>
                </tr>
                <tr class="grey lighten-5">
                  <th>Total</th>
                  <td>£{{ booking.order.total }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <booking-dialog ref="bookingDialog" />
  </div>
</template>

<script>
import BookingDialog from "./components/BookingDialog";

export default {
  components: {
    BookingDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Swimming Pool",
          disabled: true,
        },
        {
          text: "Parties",
          disabled: false,
          to: { name: "module-arnprior-pool-arnprior-pool-parties" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    booking() {
      return this.$store.getters["arnprior/bookingsStore/get"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.booking.order.customer.full_name,
      disabled: true,
    });
  },
};
</script>
